<!-- 贷款计算器 -->
<template>
  <div>
    <van-nav-bar
        title="贷款计算器"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />

    <van-collapse v-model="activeName" accordion>

      <!--   等额本息还款方式   -->
      <van-collapse-item title="等额本息还款方式" name="1" icon="gold-coin-o">
        <van-cell-group>
          <van-field v-model="capital1" label="贷款本金（元）：" type="number" input-align="right" label-width="10em"
                     placeholder="请输入贷款金额"/>
          <van-field v-model="time1" type="digit" label="贷款期限（月）：" input-align="right" label-width="10em"
                     placeholder="请输入数字1-360"/>
          <van-field v-model="rate1" type="number" label="年利率（%）：" input-align="right" label-width="10em"
                     placeholder="请输入年利率"/>
          <van-button type="primary" style="margin-top: 1em; width: 100%" @click="count1">计算</van-button>
          <van-field label="还款总额（元）：" label-width="12em" input-align="right" v-model="all_money1" readonly/>
          <van-field label="利息总额（元）：" label-width="12em" input-align="right" v-model="all_rate1" readonly/>
          <van-field label="每月还款（元）：" label-width="12em" input-align="right" v-model="one_money1" readonly/>
          <van-field label="每月还款本金（元）：" label-width="12em" input-align="right" v-model="one_capital1" readonly/>
          <van-field label="每月还款利息（元）：" label-width="12em" input-align="right" v-model="one_rate1" readonly/>
          <van-button type="primary" :disabled="info1" plain style="margin-top: 1em; width: 100%" @click="toInfo(1)">
            详细信息
          </van-button>


        </van-cell-group>
      </van-collapse-item>

      <!--   等额本金还款方式   -->
      <van-collapse-item title="等额本金还款方式" name="2" icon="gold-coin-o">
        <van-cell-group>
          <van-field v-model="capital2" label="贷款本金（元）：" type="number" label-width="10em" input-align="right"
                     placeholder="请输入贷款金额"/>
          <van-field v-model="time2" type="digit" label="贷款期限（月）：" label-width="10em" input-align="right"
                     placeholder="请输入数字1-360"/>
          <van-field v-model="rate2" type="number" label="年利率（%）：" label-width="10em" input-align="right"
                     placeholder="请输入年利率"/>
          <van-button type="primary" style="margin-top: 1em; width: 100%" @click="count2">计算</van-button>

          <van-field label="还款总额（元）：" label-width="12em" input-align="right" v-model="all_money2" readonly/>
          <van-field label="利息总额（元）：" label-width="12em" input-align="right" v-model="all_rate2" readonly/>
          <van-field label="首月还款（元）：" label-width="12em" input-align="right" v-model="one_money2" readonly/>
          <van-field label="每月还款本金（元）：" label-width="12em" input-align="right" v-model="one_capital2" readonly/>
          <van-field label="每月还款利息（元）：" label-width="12em" input-align="right" v-model="one_rate2" readonly/>
          <van-button type="primary" :disabled="info2" plain style="margin-top: 1em; width: 100%" @click="toInfo(2)">
            详细信息
          </van-button>

        </van-cell-group>
      </van-collapse-item>

      <!--   等额等息还款方式   -->
      <van-collapse-item title="等额等息还款方式" name="3" icon="gold-coin-o">
        <van-cell-group>
          <van-field v-model="capital3" label="贷款本金（元）：" type="number" label-width="10em" input-align="right"
                     placeholder="请输入贷款金额"/>
          <van-field v-model="time3" type="digit" label="贷款期限（月）：" label-width="10em" input-align="right"
                     placeholder="请输入数字1-360"/>
          <van-field v-model="rate3" type="number" label="年利率（%）：" label-width="10em" input-align="right"
                     placeholder="请输入年利率"/>
          <van-button type="primary" style="margin-top: 1em; width: 100%" @click="count3">计算</van-button>

          <van-field label="还款总额（元）：" label-width="12em" input-align="right" v-model="all_money3" readonly/>
          <van-field label="利息总额（元）：" label-width="12em" input-align="right" v-model="all_rate3" readonly/>
          <van-field label="每月还款（元）：" label-width="12em" input-align="right" v-model="one_money3" readonly/>
          <van-field label="每月还款本金（元）：" label-width="12em" input-align="right" v-model="one_capital3" readonly/>
          <van-field label="每月还款利息（元）：" label-width="12em" input-align="right" v-model="one_rate3" readonly/>
          <van-button type="primary" :disabled="info3" plain style="margin-top: 1em; width: 100%" @click="toInfo(3)">
            详细信息
          </van-button>

        </van-cell-group>
      </van-collapse-item>

      <!--   按月结息到期还本方式   -->
      <van-collapse-item title="先息后本还款方式" name="4" icon="gold-coin-o">
        <van-cell-group>
          <van-field v-model="capital4" label="贷款本金：" type="number" input-align="right" label-width="10em"
                     placeholder="请输入贷款金额"/>
          <van-field v-model="time4" type="digit" label="贷款期限（月）：" input-align="right" label-width="10em"
                     placeholder="请输入数字1-360"/>
          <van-field v-model="rate4" type="number" label="年利率（%）：" input-align="right" label-width="10em"
                     placeholder="请输入年利率"/>
          <van-button type="primary" style="margin-top: 1em; width: 100%" @click="count4">计算</van-button>

          <van-field label="还款总额（元）：" label-width="12em" input-align="right" v-model="all_money4" readonly/>
          <van-field label="利息总额（元）：" label-width="12em" input-align="right" v-model="all_rate4" readonly/>
          <van-field label="到期还款本金（元）：" label-width="12em" input-align="right" v-model="one_capital4" readonly/>
          <van-field label="每月还款利息（元）：" label-width="12em" input-align="right" v-model="one_rate4" readonly/>
          <van-button type="primary" :disabled="info4" plain style="margin-top: 1em; width: 100%" @click="toInfo(4)">
            详细信息
          </van-button>
        </van-cell-group>
      </van-collapse-item>
    </van-collapse>
  </div>
</template>
<script>

import {Button, Col, Collapse, CollapseItem, DatetimePicker, Dialog, Field, Icon, NavBar, Popup, Row} from 'vant';


export default {
  name: "calculator",

  components: {
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row,
  },

  data() {
    return {
      activeName: '1',  //显示模块
      table_head: {},   //表头

      capital1: '',     //等额本息之本金
      time1: '',        //等额本息之贷款期限
      rate1: '',        //等额本息之年利率
      info1: true,      //详细信息按钮是否可用，默认不可使用
      all_money1: '',   //还款总额
      all_rate1: '',    //利息总额
      one_money1: '',   //单月还款金额
      one_capital1: '', //单月还款本金
      one_rate1: '',    //单月还款利息
      all_data1: [],     //表格内容


      capital2: '',     //等额本金之本金
      time2: '',        //等额本金之贷款年限
      rate2: '',        //等额本金之年利率
      info2: true,      //详细信息按钮是否可用，默认不可使用
      all_money2: '',   //等额本金之还款总额
      all_rate2: '',    //等额本金之利息总额
      one_money2: '',   //等额本金之单月还款金额
      one_capital2: '', //等额本金之单月还款本金
      one_rate2: '',    //等额本金之单月还款利息
      all_data2: [],     //表格内容

      capital3: '',
      time3: '',
      rate3: '',
      info3: true,
      all_money3: '',
      all_rate3: '',
      one_money3: '',
      one_capital3: '',
      one_rate3: '',
      all_data3: [],     //表格内容

      capital4: '',
      time4: '',
      rate4: '',
      info4: true,
      all_money4: '',
      all_rate4: '',
      one_capital4: '',
      one_rate4: '',
      all_data4: [],     //表格内容

    };
  },

  computed: {},

  methods: {

    // 等额本息计算
    count1() {

      // 数据校验
      const capital_flag = this.checkCapital(this.capital1);
      if (capital_flag === false)
        return;

      const time_flag = this.checkTime(this.time1);
      if (time_flag === false)
        return;

      const rate_flag = this.checkRate(this.rate1);
      if (rate_flag === false)
        return;

      // 计算
      // 贷款额每月还款：贷款本金×[月利率×(1+月利率)^还款月数]÷{[(1+月利率)^还款月数]-1}
      // 每月还款本金：每月还款-每月还款利息
      // 每月还款利息：剩余本金*贷款月利率

      const rate_temp = this.$math.divide(this.$math.divide(this.$math.bignumber(this.rate1), this.$math.bignumber(12)), this.$math.bignumber(100))
      let temp1 = this.$math.add(this.$math.bignumber(rate_temp), this.$math.bignumber(1))
      temp1 = this.$math.pow(this.$math.bignumber(temp1), this.$math.bignumber(this.time1));
      temp1 = this.$math.multiply(this.$math.bignumber(temp1), this.$math.bignumber(rate_temp));
      temp1 = this.$math.multiply(this.$math.bignumber(this.capital1), this.$math.bignumber(temp1));
      let temp2 = this.$math.add(this.$math.bignumber(1), this.$math.bignumber(rate_temp));
      temp2 = this.$math.pow(this.$math.bignumber(temp2), this.$math.bignumber(this.time1));
      temp2 = this.$math.subtract(this.$math.bignumber(temp2), this.$math.bignumber(1));

      const te = this.$math.divide(this.$math.bignumber(temp1), this.$math.bignumber(temp2))

      this.one_money1 = te.toFixed(2);

      this.all_money1 = (this.$math.multiply(this.$math.bignumber(te), this.$math.bignumber(this.time1))).toFixed(2);
      this.all_rate1 = (this.$math.subtract(this.$math.bignumber(this.$math.multiply(this.$math.bignumber(te), this.$math.bignumber(this.time1))), this.$math.bignumber(this.capital1))).toFixed(2);

      let temp_capital = this.capital1;

      // 生成详细信息
      for (let i = 0; i < this.time1; i++) {
        const map = {
          "vol": (i + 1).toString(),
          "money": this.one_money1
        };

        map.rate = (this.$math.multiply(this.$math.bignumber(temp_capital), this.$math.bignumber(rate_temp))).toFixed(2);
        map.capital = (this.$math.subtract(this.$math.bignumber(map.money), this.$math.bignumber(map.rate))).toFixed(2);

        temp_capital = (this.$math.subtract(this.$math.bignumber(temp_capital), this.$math.bignumber(map.capital))).toFixed(2);
        if (temp_capital < 0) {
          map.new_capital = "0.00";
        } else {
          map.new_capital = temp_capital;
        }

        this.all_data1.push(map);
      }

      this.one_rate1 = this.all_data1[0].rate + "（每月递减）";
      this.one_capital1 = this.all_data1[0].capital + "（每月递增）";

      this.info1 = false;

    },

    // 等额本金计算方式
    count2() {

      //数据校验
      const capital_flag = this.checkCapital(this.capital2);
      if (capital_flag === false)
        return;

      const time_flag = this.checkTime(this.time2);
      if (time_flag === false)
        return;

      const rate_flag = this.checkRate(this.rate2);
      if (rate_flag === false)
        return;

      //计算
      // 计算每月还本付息金额=（本金/还款月数）+（本金-累计已还本金）×月利率
      // 每月本金=总本金/还款月数
      // 每月利息=（本金-累计已还本金）×月利率
      // 还款总利息=（还款月数+1）×贷款额×月利率/2
      // 还款总额=（还款月数+1）×贷款额×月利率/2+ 贷款额

      const rate_temp = this.$math.divide(this.$math.divide(this.$math.bignumber(this.rate2), this.$math.bignumber(12)), this.$math.bignumber(100))

      const temp1 = this.$math.multiply(this.$math.bignumber(this.capital2), this.$math.divide(this.$math.bignumber(rate_temp), this.$math.bignumber(2)));
      const temp2 = this.$math.multiply(this.$math.add(this.$math.bignumber(this.time2), this.$math.bignumber(1)), this.$math.bignumber(temp1));

      this.all_rate2 = temp2.toFixed(2);

      const temp3 = this.$math.add(this.$math.bignumber(temp2), this.$math.bignumber(this.capital2));
      this.all_money2 = temp3.toFixed(2);

      const temp4 = this.$math.divide(this.$math.bignumber(this.capital2), this.$math.bignumber(this.time2));
      this.one_capital2 = temp4.toFixed(2);

      let temp_capital = this.$math.bignumber(this.capital2);

      // 生成详细信息
      for (let i = 1; i <= this.time2; i++) {
        const map = {
          "vol": i.toString(),
          "capital": this.one_capital2
        };

        const temp5 = this.$math.multiply(this.$math.bignumber(temp_capital), this.$math.bignumber(rate_temp))
        map.rate = temp5.toFixed(2);
        map.money = this.$math.add(this.$math.bignumber(temp5), this.$math.bignumber(temp4)).toFixed(2)
        temp_capital = this.$math.subtract(this.$math.bignumber(temp_capital), this.$math.bignumber(temp4))
        if (temp_capital <= 0) {
          map.new_capital = "0.00"
        } else {
          map.new_capital = temp_capital.toFixed(2);
        }

        this.all_data2.push(map);
      }

      this.one_rate2 = this.all_data2[0].rate + " （每月递减）";
      this.one_money2 = this.all_data2[0].money;

      this.info2 = false;

    },

    //等本等息计算方式
    count3() {

      // 数据校验
      const capital_flag = this.checkCapital(this.capital3);
      if (capital_flag === false)
        return;

      const time_flag = this.checkTime(this.time3);
      if (time_flag === false)
        return;

      const rate_flag = this.checkRate(this.rate3);
      if (rate_flag === false)
        return;

      // 计算
      // 每月还款：每月还款本金+每月还款利息
      // 每月还款本金：贷款金额/期数
      // 每月还款利息：贷款金额*（年利率/12）*期数
      // 利息总额：贷款金额*（年利率/12）*期数
      // 还款总额：贷款金额+利息总额

      const rate_temp = this.$math.divide(this.$math.divide(this.$math.bignumber(this.rate3), this.$math.bignumber(12)), this.$math.bignumber(100))

      const temp1 = this.$math.multiply(this.$math.bignumber(this.capital3), this.$math.bignumber(rate_temp));
      this.one_rate3 = temp1.toFixed(2);

      const temp2 = this.$math.multiply(this.$math.bignumber(temp1), this.$math.bignumber(this.time3))
      this.all_rate3 = temp2.toFixed(2);

      const temp3 = this.$math.divide(this.$math.bignumber(this.capital3), this.$math.bignumber(this.time3))
      this.one_capital3 = temp3.toFixed(2);

      const temp4 = this.$math.add(this.$math.bignumber(temp1), this.$math.bignumber(temp3));
      this.one_money3 = temp4.toFixed(2);

      const temp5 = this.$math.add(this.$math.bignumber(temp2), this.$math.bignumber(this.capital3));
      this.all_money3 = temp5.toFixed(2);

      let temp_capital = this.capital3;

      // 生成详细数据
      for (let i = 1; i <= this.time3; i++) {
        const map = {
          "vol": i.toString(),
          "money": this.one_money3,
          "rate": this.one_rate3,
          "capital": this.one_capital3,
        }

        temp_capital = this.$math.subtract(this.$math.bignumber(temp_capital), this.$math.bignumber(temp3));
        if (temp_capital <= 0) {
          map.new_capital = "0.00";
        } else {
          map.new_capital = temp_capital.toFixed(2);
        }

        this.all_data3.push(map);
      }

      this.info3 = false;
    },

    // 先本后息计算
    count4() {
      // 数据校验
      const capital_flag = this.checkCapital(this.capital4);
      if (capital_flag === false)
        return;

      const time_flag = this.checkTime(this.time4);
      if (time_flag === false)
        return;

      const rate_flag = this.checkRate(this.rate4);
      if (rate_flag === false)
        return;

      // 计算
      // 到期还本：贷款金额
      // 每月还款利息：贷款金额*（年利率/12）
      // 利息总额：贷款金额*（年利率/12）*期数
      // 还款总额：贷款金额+利息总额

      this.one_capital4 = this.$math.bignumber(this.capital4).toFixed(2);

      const rate_temp = this.$math.divide(this.$math.divide(this.$math.bignumber(this.rate4), this.$math.bignumber(12)), this.$math.bignumber(100))

      const temp1 = this.$math.multiply(this.$math.bignumber(this.capital4), this.$math.bignumber(rate_temp));
      this.one_rate4 = temp1.toFixed(2);

      const temp2 = this.$math.multiply(this.$math.bignumber(temp1), this.$math.bignumber(this.time4));
      this.all_rate4 = temp2.toFixed(2);

      const temp3 = this.$math.add(this.$math.bignumber(this.capital4), this.$math.bignumber(temp2));
      this.all_money4 = temp3.toFixed(2);

      // 生成详细数据
      for (let i = 1; i < this.time4; i++) {
        const map = {
          "vol": i.toString(),
          "money": this.one_rate4,
          "rate": this.one_rate4,
          "capital": "0.00",
          "new_capital": this.capital4
        }

        this.all_data4.push(map);
      }

      const map = {
        "vol": this.time4.toString(),
        "money": this.$math.add(this.$math.bignumber(this.one_rate4), this.$math.bignumber(this.capital4)).toFixed(2),
        "rate": this.one_rate4,
        "capital": this.capital4,
        "new_capital": "0.00"
      }

      this.all_data4.push(map);

      this.info4 = false;
    },

    // 跳转到详细信息页面
    toInfo(n) {

      // 生成表头
      this.table_head.vol = "期数";
      this.table_head.money = "月供（元）";
      this.table_head.capital = "月供本金（元）";
      this.table_head.rate = "月供利息（元）";
      this.table_head.new_capital = "剩余本金（元）";

      // 保存全局变量
      this.$store.commit("SET_HEAD", this.table_head);
      if (n === 1) {
        this.$store.commit("SET_TABLE_DATA", this.all_data1)
      } else if (n === 2) {
        this.$store.commit("SET_TABLE_DATA", this.all_data2)
      } else if (n === 3) {
        this.$store.commit("SET_TABLE_DATA", this.all_data3)
      } else {
        this.$store.commit("SET_TABLE_DATA", this.all_data4)
      }

      // 跳转
      this.$router.push({path: '/info', name: 'info'});
    },

    //用于弹出提示框，数据校验时使用
    message(messages) {
      Dialog.alert({
        message: messages,
        theme: 'round-button',
      }).then(() => {
        // on close
      });
    },

    //对输入的本金数据进行校验
    checkCapital(capital) {
      if (capital === '' || capital === null) {
        this.message("您输入的本金为空，请检查输入！")
        return false;
      }
      if (capital < 0) {
        this.message("您输入的本金为负数，请检查输入！")
        return false;
      }
      if (capital === 0) {
        this.message("您输入的本金为0，请检查输入！")
        return false;
      }
      return true;
    },

    //对输入的贷款期限进行校验
    checkTime(time) {
      if (time === null || time === '') {
        this.message("您输入的贷款期限为空，请检查输入！")
        return false;
      }
      if (time < 0) {
        this.message("您输入的贷款期限为负数，请检查输入！")
        return false;
      }
      if (time === 0) {
        this.message("您输入的贷款期限为0，请检查输入！")
        return false;
      }
      if (time > 360) {
        this.message("您输入的贷款期限大于360个月，请检查输入！")
        return false;
      }
      return true;
    },

    //对输入的贷款利率进行校验
    checkRate(rate) {
      if (rate === null || rate === '') {
        this.message("您输入的年利率为空，请检查输入！")
        return false;
      }
      if (rate < 0) {
        this.message("您输入的年利率为负数，请检查输入！")
        return false;
      }
      if (rate === 0) {
        this.message("您输入的年利率为0，请检查输入！")
        return false;
      }

      return true;
    },

    //导航栏返回按钮
    onClickLeft() {
      window.location.href = "https://100001427355.retail.n.weimob.com/saas/retail/100001427355/0/shop/index?storeId=2395089355";
    },
  }
};
</script>

<style>
</style>
